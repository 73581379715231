import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import SectionHeading from "../../components/common/SectionHeading";
import Cta from "../../components/Cta";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Layout from "../../layouts";

const connectedagro = () => {
  return (
    <Layout title="Connected Agro">
      <article className="py-36 container mx-auto px-6">
        <Fade bottom>
          <section className="flex flex-col md:flex-row justify-between items-center gap-8">
            <div>
              <SectionHeading>Connected Agro</SectionHeading>
              <p className="prose mt-4 text-text-primary dark:text-text-primary-dark text-justify">
                ConnectedAgro is complete, efficient, affordable and easy to use
                IoT solution based on LoRa standard intended to monitor the
                efficiency of greenhouse / field production and boost idea of
                smart farming with unique and prioprietary ion-selectivity
                sensors and predictive AI algorithms.
              </p>
            </div>
            <StaticImage
              objectFit="cover"
              src="../../static/images/07.jpg"
              className="product-tile object-center transform group-hover:scale-105 transition duration-200 w-full md:w-1/2 rounded-xl"
            />
          </section>
        </Fade>
        <Fade bottom>
          <section className="flex flex-col md:flex-row-reverse justify-between items-center my-32 gap-8">
            <div>
              <SectionHeading caption={"Why are we different"}>
                Prioprietary solutions
              </SectionHeading>
              <p className="prose mt-4 text-text-primary dark:text-text-primary-dark text-justify">
                The prioprietary sensors with unique functionalities related to
                ion-selectivity (NO3, K, H), supported by original antenna
                design (plenary type for better signal matching), deliver
                exceptional values and benefits in the smart farming area.
                Ion-selectivity in the soil (water and mineral substratum) will
                be possible thanks to a new type of detectors consisting of
                special polymer matrix, electrode set and electro-converter
                assuming long-term, simple and maintanace-free operation.
              </p>
            </div>
            <StaticImage
              objectFit="cover"
              src="../../static/images/08.jpg"
              className="product-tile object-center transform group-hover:scale-105 transition duration-200 w-full md:w-1/2 rounded-xl"
            />
          </section>
        </Fade>
      </article>
    </Layout>
  );
};

export default connectedagro;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
